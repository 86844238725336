<template>
  <div class="t-route-maps">
    <div class="t-route-maps__map">
      <ClientOnly v-if="isMapVisible">
        <yandex-map :settings="settings" :coords="[0, 0]" :bounds="bounds" :controls="[]">
          <ymap-marker
            :marker-id="i"
            :coords="[item.address_x, item.address_y]"
            :key="i"
            v-for="(item, i) in markers"
            ref="markers"
          />
        </yandex-map>
      </ClientOnly>
    </div>
    <aside class="t-route-aside">
      <h4 class="t-route-aside__header">Места на карте</h4>
      <ol class="t-route-aside__list">
        <li class="t-route-aside__place" v-for="(route, i) in routes" :key="i">
          <span class="t-route-aside__order">{{ i + 1 }}</span>
          <span class="t-route-aside__title">{{ route.title }}</span>
        </li>
      </ol>
    </aside>
  </div>
</template>

<script>
import ClientOnly from "vue-client-only";
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "RouteOpenMapsComponent",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      bounds: [
        [0, 0],
        [0, 0],
      ],
      isMapVisible: false,
      markers: [],
      settings: {
        apiKey: "4f77a55a-eec1-4f77-961f-42cd4e50b13c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
    };
  },
  async beforeMount() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      this.markers = JSON.parse(JSON.stringify(this.routes));
      this.geoCodeMarkers(this.markers).then((data) => {
        this.markers = data.filter((item) => item !== -1);
        // Узнаю границы карты
        let minX = this.markers[0].address_x;
        let minY = this.markers[0].address_y;
        let maxX = this.markers[0].address_x;
        let maxY = this.markers[0].address_y;
        this.markers.forEach((marker) => {
          if (marker.address_x > maxX) maxX = marker.address_x;
          if (marker.address_y > maxY) maxY = marker.address_y;
          if (marker.address_x < minX) minX = marker.address_x;
          if (marker.address_y < minY) minY = marker.address_y;
        });
        this.bounds = [
          [minX, minY],
          [maxX, maxY],
        ];
        this.isMapVisible = true;
        // Сохраняю долготу и широту полей
        this.sendNewMarkers(this.markers, "tour_routes");
      });
    });
  },
  methods: {
    async geoCodeMarkers() {
      return Promise.all(
        this.markers.map(async (d) => {
          // Геокодирую через яндекс адрес и получаю дол и шир
          const res = await window.ymaps.geocode(d.address);
          if (res.geoObjects.get(0)) {
            const coords = res.geoObjects.get(0).geometry.getCoordinates();
            d.address_x = coords[0];
            d.address_y = coords[1];
            return d;
          } else if (d.address_x !== null && d.address_y !== null) {
            return d;
          }
          return -1;
        })
      );
    },
    sendNewMarkers(addresses, table_name) {
      let fd = new FormData();
      addresses
        .filter((d) => {
          return d.address_x !== null || d.address_y !== null; // Только найденные адреса сохраняются
        })
        .forEach((address, i) => {
          fd.append(`addresses[${i}][table_name]`, table_name);
          fd.append(`addresses[${i}][id]`, address.id);
          fd.append(`addresses[${i}][address_x]`, address.address_x);
          fd.append(`addresses[${i}][address_y]`, address.address_y);
        });
      if (addresses.length) {
        this.$store.dispatch("CACHE_ADDRESSES", fd);
      }
    },
  },
  computed: {
    routes() {
      return this.item.tour_routes;
    },
  },
  components: {
    yandexMap,
    ymapMarker,
    ClientOnly,
  },
};
</script>

<style lang="stylus">
.t-route-maps {
  padding 0 32px 32px
  display grid
  grid-template-columns 9fr 3fr
  grid-gap 32px
  align-items flex-start

  &__map {
    width 100%
    height 540px

    .ymap-container {
      width 100%
      height 100%
    }
  }
}
</style>
