<template>
  <section class="t-route-open">
    <h1 class="t-route-open__title">{{ item.title }}</h1>
    <div class="t-route-open__body">
      <div class="t-route-open__content">
        <RouteOpenImagesComponent :images="item.slider_img" />
        <template v-if="hasText(item.text)">
          <EditorJSComponent :text="JSON.parse(item.text)" v-if="isJson(item.text)" />
          <span v-else>{{ item.text }}</span>
        </template>
      </div>
      <RouteOpenAsideComponent :item="item" />
    </div>
    <RouteOpenMapsComponent :item="item" />
  </section>
</template>

<script>
import RouteOpenAsideComponent from "./components/RouteOpenAside.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import RouteOpenMapsComponent from "@/views/tourism/components/RouteOpenMaps.vue";
import RouteOpenImagesComponent from "@/views/tourism/components/RouteOpenImages.vue";

export default {
  name: "TourismRouteOpenPage",
  props: {
    item: {
      type: Object,
    },
  },
  methods: {
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    hasText(text) {
      if (this.isJson(text)) {
        let parsedText = JSON.parse(text);
        return !!(parsedText && parsedText.blocks && parsedText.blocks.length);
      }
      return !!text;
    },
  },
  components: {
    RouteOpenImagesComponent,
    RouteOpenMapsComponent,
    EditorJSComponent,
    RouteOpenAsideComponent,
  },
};
</script>

<style lang="stylus">
.t-route-open {
  display grid

  &__title {
    font-weight: normal;
    font-size: 1.875em;
    line-height: 36px;
    color: var(--color_black);
    margin 0;
    padding 48px 32px 32px
  }

  &__body {
    padding 0 32px 48px
    display grid
    grid-template-columns 9fr 3fr
    grid-gap 32px
    align-items flex-start
  }

  &__content {
    display grid
    grid-gap 24px
    max-width 1045px
  }
}
</style>
