<template>
  <section class="t-accommodation-open">
    <h1 class="t-accommodation-open__title">{{ item.title }}</h1>
    <div class="t-accommodation-open__body">
      <div class="t-accommodation-open__content">
        <RouteOpenImagesComponent :images="item.slider_img" />
        <EditorJSComponent v-if="item.text" :text="JSON.parse(item.text)" />
      </div>
      <AccommodationOpenAside :item="item" />
    </div>
    <RouteOpenMapsComponent :item="item" />
  </section>
</template>

<script>
import AccommodationOpenAside from "./components/AccommodationOpenAside.vue";
import EditorJSComponent from "components/EditorJSComponent.vue";
import ClientOnly from "vue-client-only";
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";
import RouteOpenMapsComponent from "@/views/tourism/components/RouteOpenMaps.vue";
import RouteOpenImagesComponent from "@/views/tourism/components/RouteOpenImages.vue";

export default {
  name: "GastronomyOpenPage",
  data() {
    return {
      isMapVisible: false,
      settings: {
        apiKey: "4f77a55a-eec1-4f77-961f-42cd4e50b13c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
    };
  },
  computed: {
    item() {
      if (this.$store.state.tourism_open.tourism) {
        return this.$store.state.tourism_open.tourism;
      }
      return {};
    },
  },
  async beforeMount() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      const res = window.ymaps.geocode(this.item.address);
      if (res.geoObjects && res.geoObjects.get(0)) {
        const coords = res.geoObjects.get(0).geometry.getCoordinates();
        this.item.address_x = coords[0];
        this.item.address_y = coords[1];
        this.isMapVisible = true;
      }
    });
  },
  components: {
    RouteOpenImagesComponent,
    RouteOpenMapsComponent,
    EditorJSComponent,
    AccommodationOpenAside,
    yandexMap,
    ymapMarker,
    ClientOnly,
  },
};
</script>

<style lang="stylus">
.t-accommodation-open {
  display grid

  &__title {
    font-weight: normal;
    font-size: 1.875em;
    line-height: 36px;
    color: var(--color_black);
    margin 0;
    padding 48px 32px 32px
  }

  &__body {
    padding 0 32px 48px
    display grid
    grid-template-columns 9fr 3fr
    grid-gap 32px
    align-items flex-start
  }

  &__content {
    display grid
    grid-gap 24px
    max-width 1045px

    .ymap-container {
      width 100%
      height 520px
    }
  }

  &__img img {
    width 100%
    max-height 480px
    object-fit contain
    object-position left
  }
}
</style>
