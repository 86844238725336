<template>
  <main class="main">
    <div class="container">
      <div class="main__inner">
        <TabListComponent :tabs="types" :active-tab="activeTypeIndex" />
        <BreadcrumbsComponent :parent-pages="parentPages" :title="item.title" />
        <Component :is="activeType.page" :item="item" />
      </div>
    </div>
  </main>
</template>

<script>
import TabListComponent from "@/views/tourism/components/TabListComponent.vue";
import RouteIcon from "@/views/tourism/components/svg/RouteIcon.vue";
import VirtualExcursionIcon from "@/views/tourism/components/svg/VirtualExcursionIcon.vue";
import AccommodationIcon from "@/views/tourism/components/svg/AccommodationIcon.vue";
import GastronomyIcon from "@/views/tourism/components/svg/GastronomyIcon.vue";
import PhotoGalleryIcon from "@/views/tourism/components/svg/PhotoGalleryIcon.vue";
import EventsIcon from "@/views/tourism/components/svg/EventsIcon.vue";
import MemoIcon from "@/views/tourism/components/svg/MemoIcon.vue";
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import TourismRouteOpenPage from "@/views/tourism/pages/route/open.vue";
import TourismAccommodationOpenPage from "@/views/tourism/pages/accommodation/open.vue";
import axios from "axios";

export default {
  async asyncData({ store, route }) {
    await axios
      .get(store.state.api + "/api/onetourism/" + route.params.id)
      .then(({ data }) => {
        store.state.mayor = data.mayor;
        store.state.agreement = data.agreement;
        store.state.tourism_open = data;
      })
      .catch(() => {});
  },
  name: "TourismOpenPage",
  data() {
    return {
      types: [
        {
          id: "routes",
          title: "Маршруты",
          icon: RouteIcon,
          route: { name: "tourism", params: { link: "routes" } },
          page: TourismRouteOpenPage,
        },
        {
          id: "excursions",
          title: "Виртуальные экскурсии",
          icon: VirtualExcursionIcon,
          route: { name: "tourism", params: { link: "excursions" } },
        },
        {
          id: "accommodation",
          title: "Размещение",
          icon: AccommodationIcon,
          route: { name: "tourism", params: { link: "accommodation" } },
          page: TourismAccommodationOpenPage,
        },
        {
          id: "gastronomy",
          title: "Гастрономия",
          icon: GastronomyIcon,
          route: { name: "tourism", params: { link: "gastronomy" } },
          page: TourismAccommodationOpenPage,
        },
        {
          id: "photogallery",
          title: "Фотогалерея",
          icon: PhotoGalleryIcon,
          route: { name: "tourism", params: { link: "photogallery" } },
        },
        {
          id: 7,
          title: "События",
          icon: EventsIcon,
          route: { name: "posters" },
        },
        {
          id: "memo",
          title: "Памятка туристу",
          icon: MemoIcon,
          route: { name: "tourism", params: { link: "memo" } },
        },
      ],
    };
  },
  computed: {
    item() {
      if (this.$store.state.tourism_open.tourism) {
        return this.$store.state.tourism_open.tourism;
      }
      return {};
    },
    parentPages() {
      let parentRoute = {};
      if (this.activeTypeIndex !== -1) {
        // Получение родительской страницы по параметру link в роутере
        parentRoute = Object.assign(this.activeType.route, {
          title: this.activeType.title,
        });
      }
      return [parentRoute];
    },
    activeType() {
      return this.types[this.activeTypeIndex] || -1;
    },
    activeTypeIndex() {
      return this.types.findIndex((t) => t.id === this.$route.params.link);
    },
  },
  components: { BreadcrumbsComponent, TabListComponent },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
};
</script>
