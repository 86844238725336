<template>
  <aside class="t-accommodation-aside" v-if="contacts.length || tour_services.length">
    <template v-if="contacts.length">
      <h4 class="t-accommodation-aside__header">{{ item.title }}</h4>
      <ul class="t-accommodation-aside__list" v-for="(contact, i) in contacts" :key="i">
        <li class="t-accommodation-aside__contact">
          <AccountIcon />
          <span class="t-accommodation-aside__subtitle">Адрес</span>
          <span class="t-accommodation-aside__title">{{ contact.address }}</span>
        </li>
        <li class="t-accommodation-aside__contact">
          <TimeIcon />
          <span class="t-accommodation-aside__subtitle">Время работы</span>
          <span class="t-accommodation-aside__title">{{ contact.schedule }},<br />{{ contact.weekend }}</span>
        </li>
        <li class="t-accommodation-aside__contact">
          <PhoneIcon />
          <span class="t-accommodation-aside__subtitle">Телефон</span>
          <span class="t-accommodation-aside__title">{{ contact.phone }}</span>
        </li>
        <li class="t-accommodation-aside__contact">
          <SocialsIcon />
          <span class="t-accommodation-aside__subtitle">Социальные сети</span>
          <span class="t-accommodation-aside__title">
            <template v-for="(social, i) in contact.tour_socials">
              <a :key="i" :href="social.link" rel="nofollow">{{ social.title }}/{{ social.nick_name }}</a>
              <br :key="i" />
            </template>
          </span>
        </li>
      </ul>
    </template>
    <template v-if="tour_services.length">
      <h4 class="t-accommodation-aside__header">Сервисы</h4>
      <ul class="t-accommodation-aside__services">
        <li v-for="(service, i) in tour_services" :key="i">
          <figure class="icon" v-html="service.icon"></figure>
          <span>{{ service.title }}</span>
        </li>
      </ul>
    </template>
  </aside>
</template>

<script>
import AccountIcon from "components/svg/AccountIcon.vue";
import TimeIcon from "components/svg/TimeIcon.vue";
import PhoneIcon from "components/svg/Phone2Icon.vue";
import SocialsIcon from "components/svg/SocialsIcon.vue";

export default {
  name: "AccommodationOpenAsideComponent",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    tour_services() {
      return this.item.tour_services.map((item) => item.service);
    },
    contacts() {
      return this.item.tour_contact.map((item) => item.contact);
    },
  },
  components: {
    SocialsIcon,
    PhoneIcon,
    TimeIcon,
    AccountIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.t-accommodation-aside {
  background var(--color_gray_light)
  padding 24px
  display grid
  grid-gap 32px
  width 100%
  min-width 280px

  &__header {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color_dark);
    margin 0
  }

  &__list {
    display grid
    grid-gap 24px
  }

  &__place
  &__contact {
    display grid
    padding-left 28px
    position relative

    .icon {
      svg(var(--main_border_color))
      absolute left top
      width 20px
      height 20px

      svg {
        width 100%
        height 100%
      }
    }
  }

  &__services {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 8px
    width 100%

    li {
      height 112px
      background: var(--color_white);
      border-radius: 4px;
      display flex
      flex-direction column
      align-items center
      justify-content center
      grid-gap 8px

      .icon {
        width 32px
        height 32px

        svg {
          width 100%
          height 100%
          svg(var(--color_blue))
        }
      }

      span {
        font-weight: normal;
        font-size: 1em;
        line-height: 24px;
        text-align: center;
        color: var(--color_gray_dark);
      }
    }
  }

  &__order {
    absolute left top
  }

  &__title
  &__order {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
  }

  &__subtitle {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_gray_dark);
  }
}
</style>
