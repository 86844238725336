<template>
  <aside class="t-route-aside">
    <template v-if="routes && routes.length">
      <h4 class="t-route-aside__header">Маршрут проведения</h4>
      <ol class="t-route-aside__list">
        <li class="t-route-aside__place" v-for="(route, i) in routes" :key="i">
          <span class="t-route-aside__order">{{ i + 1 }}</span>
          <span class="t-route-aside__title">{{ route.title }}</span>
          <span class="t-route-aside__subtitle">{{ route.note }}</span>
        </li>
      </ol>
    </template>
    <h4 class="t-route-aside__header">Контактные данные гида</h4>
    <ul class="t-route-aside__list" v-for="(contact, i) in contacts" :key="i">
      <li class="t-route-aside__contact">
        <AccountIcon />
        <span class="t-route-aside__subtitle">ФИО экскурсовода</span>
        <span class="t-route-aside__title">{{ contact.title }}</span>
      </li>
      <li class="t-route-aside__contact">
        <PhoneIcon />
        <span class="t-route-aside__subtitle">Телефон</span>
        <span class="t-route-aside__title">{{ contact.phone }}</span>
      </li>
      <li class="t-route-aside__contact">
        <TimeIcon />
        <span class="t-route-aside__subtitle">Время работы</span>
        <span class="t-route-aside__title">{{ contact.schedule }},<br />{{ contact.weekend }}</span>
      </li>
    </ul>
  </aside>
</template>

<script>
import AccountIcon from "components/svg/AccountIcon.vue";
import PhoneIcon from "components/svg/Phone2Icon.vue";
import TimeIcon from "components/svg/TimeIcon.vue";

export default {
  name: "RouteOpenAsideComponent",
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    routes() {
      return this.item.tour_routes;
    },
    contacts() {
      return this.item.tour_contact.map((item) => item.contact);
    },
  },
  components: { TimeIcon, PhoneIcon, AccountIcon },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
.t-route-aside {
  background var(--color_gray_light)
  padding 24px
  display grid
  grid-gap 32px
  width 100%

  &__header {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color_dark);
    margin 0
  }

  &__list {
    display grid
    grid-gap 24px
  }

  &__place
  &__contact {
    display grid
    padding-left 28px
    position relative

    .icon {
      absolute left top
      width 20px
      height 20px

      svg {
        width 100%
        height 100%
        svg(var(--main_border_color))
      }
    }
  }

  &__order {
    absolute left top
  }

  &__title
  &__order {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
  }

  &__subtitle {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_gray_dark);
  }
}
</style>
