<template>
  <figure class="icon">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99984 1.66797C8.35166 1.66797 6.7405 2.15671 5.37009 3.07239C3.99968 3.98807 2.93158 5.28956 2.30084 6.81227C1.67011 8.33499 1.50509 10.0105 1.82663 11.6271C2.14817 13.2436 2.94185 14.7284 4.10728 15.8939C5.27272 17.0593 6.75758 17.853 8.37409 18.1745C9.9906 18.4961 11.6662 18.331 13.1889 17.7003C14.7116 17.0696 16.0131 16.0015 16.9288 14.6311C17.8444 13.2606 18.3332 11.6495 18.3332 10.0013C18.3332 8.90695 18.1176 7.82332 17.6988 6.81227C17.28 5.80123 16.6662 4.88257 15.8924 4.10875C15.1186 3.33492 14.1999 2.7211 13.1889 2.30231C12.1778 1.88352 11.0942 1.66797 9.99984 1.66797V1.66797ZM9.99984 16.668C8.6813 16.668 7.39237 16.277 6.29604 15.5444C5.19971 14.8119 4.34523 13.7707 3.84064 12.5525C3.33606 11.3344 3.20404 9.99391 3.46127 8.7007C3.71851 7.40749 4.35345 6.21961 5.2858 5.28726C6.21815 4.35491 7.40603 3.71997 8.69924 3.46273C9.99245 3.2055 11.3329 3.33752 12.5511 3.84211C13.7692 4.34669 14.8104 5.20117 15.543 6.2975C16.2755 7.39383 16.6665 8.68276 16.6665 10.0013C16.6665 11.7694 15.9641 13.4651 14.7139 14.7153C13.4636 15.9656 11.768 16.668 9.99984 16.668V16.668ZM9.99984 5.0013C9.77883 5.0013 9.56687 5.0891 9.41059 5.24538C9.25431 5.40166 9.16651 5.61362 9.16651 5.83464V9.51797L7.41651 10.5263C7.25597 10.6173 7.13017 10.7589 7.05882 10.9291C6.98748 11.0993 6.97463 11.2883 7.0223 11.4666C7.06996 11.6448 7.17543 11.8022 7.32218 11.9141C7.46894 12.0259 7.64866 12.0859 7.83317 12.0846C7.97915 12.0856 8.12284 12.0483 8.24984 11.9763L10.4165 10.7263L10.4915 10.6513L10.6248 10.543C10.6574 10.5017 10.6854 10.457 10.7082 10.4096C10.7353 10.3649 10.7577 10.3174 10.7748 10.268C10.7975 10.215 10.8116 10.1587 10.8165 10.1013C10.8165 10.1013 10.8332 10.0013 10.8332 10.0013V5.83464C10.8332 5.61362 10.7454 5.40166 10.5891 5.24538C10.4328 5.0891 10.2209 5.0013 9.99984 5.0013Z"
        fill="#A7A8AB"
      />
    </svg>
  </figure>
</template>

<script>
export default {
  name: "TimeIcon",
};
</script>
