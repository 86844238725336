<template>
  <div class="t-route-images" id="lightgallery">
    <a
      :href="item | image($store.state.api)"
      :class="{
        't-route-images__main': i === 0,
        't-route-images__small': i === 1,
      }"
      v-show="i <= 1"
      v-for="(item, i) in images"
      :key="i"
    >
      <span v-if="i === 1">+ {{ images.length - 1 }} фото</span>
      <img :src="item | image($store.state.api)" />
    </a>
  </div>
</template>

<script>
export default {
  name: "RouteOpenImagesComponent",
  props: {
    images: {
      type: Array,
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    require(["lightgallery.js"], () => {
      // eslint-disable-next-line no-undef
      require(["lg-thumbnail.js"], () => {
        // eslint-disable-next-line no-undef
        lightGallery(document.getElementById("lightgallery"), {
          thumbnail: true,
        });
      });
    });
  },
};
</script>

<style lang="stylus">
@import "~@/styles/parts/lightgallery.styl"
.t-route-images {
  display flex
  gap 16px
  align-items flex-start
  justify-content flex-start

  &__main {
    width 100%
    height auto
    overflow hidden

    &:hover img {
      transform scale(1.08)
    }

    img {
      width 100%
      height 100%
      max-height 640px
      transition .2s
    }
  }

  &__small {
    width 196px
    height 155px
    position relative
    overflow hidden

    &:hover img {
      transform scale(1.08)
    }

    span {
      width 100%
      height 100%
      absolute left top
      background: var(--color_black_o5);
      font-weight: 500;
      font-size: 1.125em;
      line-height: 24px;
      color: var(--color_white);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width 100%
      height 100%
      object-fit cover
      object-position center
      transition .2s
    }
  }
}
</style>
